body {
  background-image: url("https://clientresources.eskillz.com/freshdesk/images/AdobeStock_635002974.jpeg");
  background-position: center right;
  background-size: cover;
}

#root {
  margin: 0 auto auto;
  padding: 0;
  width: 100%;
}

hr.redline {
  border: 1px solid #84a43f;
}

.header {
  background-color: #007fae;
  color: #fff;
  font-weight: bold;
  text-align: left;
  padding: 10px 30px;
  font-size: 16px;
}

.footer {
  background-color: #e7e7e7;
  color: #404040;
  text-align: right;
  font-size: 12px;
  position: fixed;
  bottom: 0;
  width: 100%;
  line-height: 50px;
  vertical-align: middle;
}
.footer img {
  max-height: 25px;
  margin-right: 50px;
  vertical-align: middle;
}
.modal-window {
  background: rgba(255, 255, 255, 0.85);
  max-width: 600px;
  border-radius: 8px;
  margin: 50px auto 20px;
  filter: drop-shadow(0px 5px 8px rgba(0, 0, 0, 0.35));
  font-family: "Open Sans", sans-serif;
  padding: 30px;
  font-size: 14px;
  text-align: center;
}

.modal-window.instruct {
  margin-top: 0;
  margin-bottom: 90px;
}

.modal-window button {
  width: 100%;
  margin: auto;
  border-radius: 6px;
  color: #fff;
  transition: all 0.5s ease;
  background-color: #00aeef;
  border-color: #00aeef;
  border-bottom: 3px solid #007fae;
  text-decoration: none;
  cursor: pointer;
  padding: 6px 0;
  display: block;
  font-weight: bold;
  font-size: 16px;
}

.modal-window button:hover {
  border-color: #007fae;
  background-color: #007fae;
  border-bottom: 3px solid #233651;
  color: #fff;
}

.modal-window p.nogap {
  margin: 0;
}

.header_instr {
  font-weight: bold;
  font-size: 16px;
  text-align: left;
}

.summary {
  padding-bottom: 20px;
}

ol,
li {
  text-align: left;
}

p.instruct {
  text-align: left;
  font-weight: bold;
}
